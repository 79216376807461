import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Publications from "../components/Publications";

import FullWidthImage from "../components/FullWidthImage";
import { getImage } from "gatsby-plugin-image";

// eslint-disable-next-line
export const PublicationPageTemplate = ({
  title,
  content,
  contentComponent,
  lang,
  publications,
  image,
}) => {
  const PageContent = contentComponent || Content;

  const journal = publications.filter((item) => {
    return item.node.type === "JournalE" || item.node.type === "JournalJ";
  });
  const journalE = publications.filter((item) => {
    return item.node.type === "JournalE";
  });
  const procE = publications.filter((item) => {
    return item.node.type === "ProcE";
  });
  const oralJ = publications.filter((item) => {
    return (
      item.node.type === "ProcJ" ||
      item.node.type === "Zen" ||
      item.node.type === "MiscJ"
    );
  });
  const speech = publications.filter((item) => {
    return item.node.type === "SpeechJ" || item.node.type === "SpeechE";
  });
  const speechE = publications.filter((item) => {
    return item.node.type === "SpeechE";
  });
  const article = publications.filter((item) => {
    return item.node.type === "ArticleJ" || item.node.type === "ArticleE";
  });
  const articleE = publications.filter((item) => {
    return item.node.type === "ArticleE";
  });
  const book = publications.filter((item) => {
    return item.node.type === "BookJ";
  });

  const heroImage = getImage(image) || image;

  return (
    <div>
      <Navbar lang={lang} />
      <FullWidthImage img={heroImage} title={title} />
      <section className="section section--gradient">
        <Helmet
          title={`${title} | ${
            lang === "ja" ? "飯山研究室" : "Iiyama Laboratory"
          }`}
        />
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section">
                <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                  {title}
                </h2>
                <PageContent className="content" content={content} />
                <div className="content">
                  <ul>
                    <li>
                      <a href="#journal">
                        {lang === "ja" ? "論文誌" : "Journals"}
                      </a>
                    </li>
                    <li>
                      <a href="#proceeding">
                        {lang === "ja" ? "国際会議" : "Proceedings"}
                      </a>
                    </li>
                    {lang === "ja" ? (
                      <li>
                        <a href="#book">書籍</a>
                      </li>
                    ) : (
                      <div></div>
                    )}
                    <li>
                      <a href="#talk">
                        {lang === "ja" ? "招待講演" : "Invited Talk"}
                      </a>
                    </li>
                    <li>
                      <a href="#article">
                        {lang === "ja" ? "解説記事" : "Article"}
                      </a>
                    </li>
                    {lang === "ja" ? (
                      <li>
                        <a href="#oral">口頭発表</a>
                      </li>
                    ) : (
                      <div></div>
                    )}
                  </ul>
                </div>
                <Publications
                  title={lang === "ja" ? "論文誌" : "Journals"}
                  data={lang === "ja" ? journal : journalE}
                  anchor="journal"
                />
                <div>
                  <p>
                    <a href="#journal">Top</a>
                  </p>
                </div>
                <Publications
                  title={lang === "ja" ? "国際会議" : "Proceedings"}
                  data={procE}
                  anchor="proceeding"
                />
                <div>
                  <p>
                    <a href="#journal">Top</a>
                  </p>
                </div>
                {lang === "ja" ? (
                  <Publications title="書籍" data={book} anchor="book" />
                ) : (
                  <div></div>
                )}
                <Publications
                  title={lang === "ja" ? "招待講演" : "Invited Talk"}
                  data={lang === "ja" ? speech : speechE}
                  anchor="talk"
                />
                <div>
                  <p>
                    <a href="#journal">Top</a>
                  </p>
                </div>
                <Publications
                  title={lang === "ja" ? "解説記事" : "Article"}
                  data={lang === "ja" ? article : articleE}
                  anchor="article"
                />
                <div>
                  <p>
                    <a href="#journal">Top</a>
                  </p>
                </div>
                {lang === "ja" ? (
                  <Publications title="口頭発表" data={oralJ} anchor="oral" />
                ) : (
                  <div></div>
                )}
                {lang === "ja" ? (
                  <div>
                    <p>
                      <a href="#journal">Top</a>
                    </p>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer lang={lang} />
    </div>
  );
};

PublicationPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const PublicationPage = ({ data }) => {
  const { markdownRemark: post, allPublicationXlsxPublication: publications } =
    data;

  return (
    <Layout>
      <PublicationPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        lang={post.frontmatter.lang}
        content={post.html}
        publications={publications.edges}
        image={post.frontmatter.image}
      />
    </Layout>
  );
};

PublicationPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PublicationPage;

export const publicationPageQuery = graphql`
  query publicationPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        lang
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
    allPublicationXlsxPublication(sort: { order: DESC, fields: date }) {
      edges {
        node {
          authors
          date
          DOI
          journal
          pages
          title
          type
          html
        }
      }
    }
  }
`;
