import * as React from "react";

export const Publications = ({ title, data, anchor }) => (
  <div className="publication">
    <h2 className="is-size-4 has-text-weight-bold has-text-primary">
      <a name={anchor}>{title}</a>
    </h2>
    {data.map((paper, count) => (
      <div className="paper-item">
        <p className="has-text-weight-semibold">
          {count + 1}: {paper.node.title}
        </p>
        <p className="none">{paper.node.authors}</p>
        <p className="paper-page">
          {paper.node.journal}, {paper.node.pages}, {paper.node.date}
        </p>
        <p className="is-family-code">
          {paper.node.html ? (
            <a href={paper.node.html}>{paper.node.DOI}</a>
          ) : (
            paper.node.DOI
          )}
        </p>
      </div>
    ))}
  </div>
);

export default Publications;
